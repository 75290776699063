<template>
  <overlay-scrollbars class="page page-offer" ref="os">
    <Compliance />
    <div class="box box_selectedAcc">
      <div class="header">
        <div class="title">INDIVIDUALLY SELECTED ACCOUNTS FOR YOUR TYPE OF BUSINESS</div>
      </div>
      <div class="content">
        <div class="block block__bankAccount">
          <div class="hdr">
            <Bank2 class="img" /><div class="ttl">Bank account</div>
          </div>
          <Table
            :options="bankAcc" :key="bankAccKey"
            @apply="apply" @change-page="getBankOffers" @sort="sortBy" @scroll-top="toElTop"
          />
        </div>
        <div class="block block__merchantAccount">
          <div class="hdr">
            <CreditCards class="img" /><div class="ttl">Merchant account</div>
          </div>
          <Table
            :options="merchantAcc" :key="merchantAccKey"
            @apply="apply" @change-page="getMerchantOffers" @sort="sortBy" @scroll-top="toElTop"
          />
        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>

<script>
import Info from '@/components/elem/Info.vue'
import Compliance from '@/components/elem/Compliance.vue'
import Table from '@/components/elem/Table.vue'
import Bank2 from '@/components/img/Bank2.vue'
import CreditCards from '@/components/img/CreditCards.vue'
import Pagination from 'laravel-vue-pagination'

export default {
  components: {
    Info,
    Compliance,
    Table,
    Bank2,
    CreditCards,
    Pagination,
  },
  data: () => ({
    risk: '',
    bankOffersData: {},
    bankAccKey: 0,
    merchantOffersData: {},
    merchantAccKey: 0,
    bankAcc: {
      objName: 'bankAcc',
      colsWidth: ['3%', '10%', '10%', '11%', '12%', '15%', '15%', '12%', '10%'],
      header: [
        { ttl: '#', info: null },
        {
          ttl: 'Name',
          info: {
            pos: 'tr',
            txt: 'The names will be available to you after passing Compliance'
          },
          sort: 'type_of_license'
        },
        { ttl: 'Country', info: null },
        { ttl: 'Type of Account', info: null },
        {
          ttl: 'Open/Integration fee',
          info: {
            pos: 'tr',
            txt: 'All Fee is a public providing by Financial Institutions.<br/> The Fee, depand Client type of business and legal structure'
          },
          sort: 'open_integration_fee'
        },
        { ttl: 'Incoming fee', info: null },
        { ttl: 'Outgoing fee', info: null },
        {
          ttl: 'Onboarding time',
          info: {
            pos: 'tl',
            txt: 'Actual deadlines, start from date of  providing  company packege of documents'
          },
          sort: 'onboarding_time'
        },
        { ttl: 'Request', info: null },
      ],
      body: [],
      paginationData: null,
      sort: {
        name: '',
        order: 'asc',
        loading: false
      }
    },
    merchantAcc: {
      objName: 'merchantAcc',
      colsWidth: ['3%', '10%', '10%', '11%', '12%', '15%', '15%', '12%', '10%'],
      header: [
        { ttl: '#', info: null },
        {
          ttl: 'Name',
          info: {
            pos: 'tr',
            txt: 'The names will be available to you after passing Compliance'
          },
          sort: 'type_of_license'
        },
        { ttl: 'Country', info: null },
        { ttl: 'Type of Zone', info: null },
        {
          ttl: 'Open/Integration fee',
          info: {
            pos: 'tr',
            txt: 'All Fee is a public providing by Financial Institutions.<br/> The Fee, depand Client type of business and legal structure'
          },
          sort: 'open_integration_fee'
        },
        { ttl: 'Incoming fee', info: null },
        { ttl: 'Outgoing fee', info: null },
        {
          ttl: 'Onboarding time',
          info: {
            pos: 'tl',
            txt: 'Actual deadlines, start from date of  providing  company packege of documents'
          },
          sort: 'onboarding_time'
        },
        { ttl: 'Request', info: null },
      ],
      body: [],
      individual: [],
      paginationData: null,
      sort: {
        name: '',
        order: 'asc',
        loading: false
      }
    },
  }),
  created () {
    this.$store.dispatch('setUser')
    this.getBankOffers()
    this.getMerchantOffers()
    this.risk = this.$store.state.user && this.$store.state.user.risk ? this.$store.state.user.risk : '';
    this.paid = this.$store.state.user && this.$store.state.user.is_paid == 1 ? 'Paid' : 'Unpaid';
  },
  methods: {
    getFinName (el) {
      return !el.provider_id && !el.is_legal_company
        ? el.financial_institution_name
        : {
            type: 'info',
            html: el.financial_institution_name,
            info: {
              pos: 'tr',
              txt: 'Provided by Legal company'
            },
          }
    },
    getOif (el) {
      return el.oif_min_max && el.open_integration_fee_max
        ? this.number_format(el.open_integration_fee, 2, '.', ' ') +' - '+ this.number_format(el.open_integration_fee_max, 2, '.', ' ') +' €'
        : this.number_format(el.open_integration_fee, 2, '.', ' ') +' €'
    },
    getBankOffers (page = 1) {
      const that = this
      const basePath = process.env.VUE_APP_BACKEND_API_URL + 'offers/bank-accounts?page=' + page
      const sortParams = '&sort=' + this.bankAcc.sort.name + '&order=' + this.bankAcc.sort.order
      axios.get(basePath + sortParams)
        .then(function (response) {
          that.bankAcc.paginationData = that.bankOffersData = response.data
          that.bankAcc.body = []
          that.bankAcc.individual = []
          that.bankOffersData.data.forEach(el => {
            const individual = (parseInt(el.user_id) === parseInt(that.$store.state.user.id)) ? true : false;
            const country = el.country_code && el.country ? el.country.name : ''
            that.bankAcc.individual.push(individual);
            if (!el.applied) {
              that.bankAcc.body.push([
                el.serial_number, that.getFinName(el), country, el.account_type,
                that.getOif(el),
                el.incoming_fee, el.outgoing_fee, el.onboarding_time_text, { type: 'apply-btn', html: 'Apply', applyType: 'bank-offer', id: el.id }
              ])
            } else {
              const timeLeftDaysNumber = (el.time_left) ? el.time_left.days_number : ''
              const timeLeftHoursNumber = (el.time_left) ? el.time_left.hours_number : ''
              that.bankAcc.body.push([
                el.serial_number, that.getFinName(el), country, el.account_type, that.getOif(el), el.incoming_fee, el.outgoing_fee, { type: 'deadline_info', days: timeLeftDaysNumber, hours: timeLeftHoursNumber}, { type: 'cancel', applyType: 'bank-offer', id: el.id }
              ])
            }
          })
          if (that.bankAcc.sort.name) that.bankAcc.sort.loading = false
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    getMerchantOffers (page = 1) {
      const that = this
      const basePath = process.env.VUE_APP_BACKEND_API_URL + 'offers/merchant-accounts?page=' + page
      const sortParams = '&sort=' + this.merchantAcc.sort.name + '&order=' + this.merchantAcc.sort.order
      axios.get(basePath + sortParams)
        .then(function (response) {
          that.merchantAcc.paginationData = that.merchantOffersData = response.data
          that.merchantAcc.body = []
          that.merchantAcc.individual = []
          that.merchantOffersData.data.forEach(el => {
            const individual = (parseInt(el.user_id) === parseInt(that.$store.state.user.id)) ? true : false
            const country = el.country_code && el.country ? el.country.name : ''
            that.merchantAcc.individual.push(individual)
            if (!el.applied) {
              that.merchantAcc.body.push([
                el.serial_number, that.getFinName(el), country, el.account_type, that.getOif(el), el.incoming_fee, el.outgoing_fee, el.onboarding_time_text, { type: 'apply-btn', html: 'Apply', applyType: 'merchant-offer', id: el.id }
              ])
            } else {
              const timeLeftDaysNumber = (el.time_left) ? el.time_left.days_number : ''
              const timeLeftHoursNumber = (el.time_left) ? el.time_left.hours_number : ''
              that.merchantAcc.body.push([
                el.serial_number, that.getFinName(el), country, el.account_type, that.getOif(el), el.incoming_fee, el.outgoing_fee, { type: 'deadline_info', days: timeLeftDaysNumber, hours: timeLeftHoursNumber}, { type: 'cancel', applyType: 'merchant-offer', id: el.id }
              ])
            }
          })
          if (that.merchantAcc.sort.name) that.merchantAcc.sort.loading = false
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    apply (payload) {
      const that = this
      axios.post(process.env.VUE_APP_BACKEND_API_URL + 'offers/toogle-apply/' + payload.id)
        .then((response) => {
          const res = response.data
          if (payload.applyType == 'bank-offer') {
            const data = that.bankOffersData.data
            for (var i = 0; i < data.length; i++) {
              const el = data[i]
              if (el.id === payload.id) {
                this.$noty.success("Apply status was successfully changed")
                if (res.applied) {
                  this.$noty.success("Please fill out the documents on the Compliance page and check your mail")
                }
                const individual = (parseInt(el.user_id) === parseInt(that.$store.state.user.id)) ? true : false
                const country = el.country_code && el.country ? el.country.name : ''
                that.bankAcc.individual[i] = individual
                if (!res.applied) {
                  that.bankAcc.body[i] = [
                    el.serial_number, that.getFinName(el), country, el.account_type, that.getOif(el), el.incoming_fee, el.outgoing_fee, el.onboarding_time_text, { type: 'apply-btn', html: 'Apply', applyType: 'bank-offer', id: el.id }
                  ]
                } else {
                  const timeLeftDaysNumber = (res.time_left) ? res.time_left.days_number : ''
                  const timeLeftHoursNumber = (res.time_left) ? res.time_left.hours_number : ''
                  that.bankAcc.body[i] = [
                    el.serial_number, that.getFinName(el), country, el.account_type, that.getOif(el), el.incoming_fee, el.outgoing_fee, { type: 'deadline_info', days: timeLeftDaysNumber, hours: timeLeftHoursNumber}, { type: 'cancel', applyType: 'bank-offer', id: el.id }
                  ]
                }
                that.bankAccKey++
              }
            }
          } else if (payload.applyType == 'merchant-offer') {
            const data = that.merchantOffersData.data
            for (var i = 0; i < data.length; i++) {
              const el = data[i]
              if (el.id === payload.id) {
                this.$noty.success("Apply status was successfully changed")
                if (res.applied) {
                  this.$noty.success("Please fill out the documents on the Compliance page and check your mail")
                }
                const individual = (parseInt(el.user_id) === parseInt(that.$store.state.user.id)) ? true : false
                const country = el.country_code && el.country ? el.country.name : ''
                that.merchantAcc.individual[i] = individual
                if (!res.applied) {
                  that.merchantAcc.body[i] = [
                    el.serial_number, that.getFinName(el), country, el.account_type, that.getOif(el), el.incoming_fee, el.outgoing_fee, el.onboarding_time_text, { type: 'apply-btn', html: 'Apply', applyType: 'merchant-offer', id: el.id }
                  ]
                } else {
                  const timeLeftDaysNumber = (res.time_left) ? res.time_left.days_number : ''
                  const timeLeftHoursNumber = (res.time_left) ? res.time_left.hours_number : ''
                  that.merchantAcc.body[i] = [
                    el.serial_number, that.getFinName(el), country, el.account_type, that.getOif(el), el.incoming_fee, el.outgoing_fee, { type: 'deadline_info', days: timeLeftDaysNumber, hours: timeLeftHoursNumber}, { type: 'cancel', applyType: 'merchant-offer', id: el.id }
                  ]
                }
                that.merchantAccKey++
              }
            }
          }

        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    sortBy (data) {
      this[data.obj].sort.loading = true
      this[data.obj].sort.order = this[data.obj].sort.name === data.sort && this[data.obj].sort.order === 'asc'
        ? 'desc' : 'asc'
      this[data.obj].sort.name = data.sort

      switch (data.obj) {
        case 'bankAcc':
          this.getBankOffers(1)
          break;
        case 'merchantAcc':
          this.getMerchantOffers(1)
          break;
      }
    },
    toElTop (el) {
      this.$refs.os.osInstance().scroll({ el: el, margin: 10 }, 1500)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-offer {
  .box {
    background-color: #000000;

    &_selectedAcc {
      .header {
        .title {
          text-align: center;

          &::before {
            content: none;
          }
        }
      }

      .content {
        padding: 0 0 1rem;

        .block {
          margin-bottom: 1.5rem;

          .hdr {
            display: flex;
            align-items: center;
            margin-left: .75rem;
            margin-bottom: .5rem;

            svg.img {
              width: 1.75rem;
              height: 1.75rem;
              margin-right: .5rem;
            }

            .ttl {
              padding: .25rem .5rem;
            }
          }

          ::v-deep .tbl {
            position: relative;
            background-color: #000000;

            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              right: 0;
              bottom: 0;
              background: linear-gradient(124.81deg, #323940 0%, rgba(50, 57, 64, 0.9) 46.79%, rgba(0, 0, 0, 0.02) 100%);
              box-shadow:
                20px 30px 100px rgba(0, 0, 0, 0.05),
                inset -5px -5px 10px rgba(255, 255, 255, 0.05),
                inset 5px 5px 10px rgba(255, 255, 255, 0.05);
              backdrop-filter: blur(20px);
              opacity: 0.5;
            }

            > * {
              position: relative;
            }
          }

          &__bankAccount {
            .hdr {
              ::v-deep svg.img {
                path {
                  fill: #24BAD6;
                }
              }
            }

            ::v-deep .tbl {
              &_header {
                background-color: rgba(36, 187, 214, 0.5);
              }
            }
          }

          &__merchantAccount {
            .hdr {
              ::v-deep svg.img {
                path {
                  fill: #FF056C;
                }
              }
            }

            ::v-deep .tbl {
              &_header {
                background-color: rgba(255, 5, 109, 0.5);
              }
            }
          }
        }
      }
    }
  }
}

.pagination {
    background-color: white;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem;
}

.page-link span {
    color: white;
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
}

.page-link:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    border: 1px solid #dee2e6;
    color: white !important;
}
</style>
